/* eslint-disable react/no-danger */
import React from 'react';
import FooterToggler from '../../02-molecules/footer-toggler';
import FooterBottom from '../footer-bottom';
import FooterSections from '../footer-sections';
import { LinkProps } from '../../01-atoms/link';
import { FooterSectionProps } from '../../02-molecules/footer-section';
import ToTopLabel from '../../02-molecules/to-top-label';

interface FooterProps {
    footerSections: FooterSectionProps[],
    footerBottomLinks: LinkProps[];
    title?: string;
    fixed?: boolean;
    toTopButtonLabel?: string;
}

const togglerOptions = {
    animate: true,
    activeCls: 'a-footer-toggler--active',
    targetActiveCls: 'o-footer__toggable--active',
};

const Footer = ({
    title,
    fixed,
    footerSections,
    footerBottomLinks,
    toTopButtonLabel,
}: FooterProps) => {
    const sections = <FooterSections footerSections={footerSections} />;
    const bottom = <FooterBottom footerBottomLinks={footerBottomLinks} />;

    const footerToTopButton = toTopButtonLabel !== null ? (
        <div className="o-footer__toTopButton" data-fixed={fixed}>
            <div className="container-fluid">
                <ToTopLabel label={toTopButtonLabel} />
            </div>
        </div>
    ) : '';

    const footerContent = fixed ? (
        <>
            <FooterToggler
                controls="footer-toggable"
                text={title}
                options={togglerOptions}
            />
            <div className="o-footer__toggable" id="footer-toggable">
                <div className="o-footer__sections-container">
                    <div className="l-constrainer container-fluid">
                        {sections}
                    </div>
                </div>
                {bottom}
            </div>
        </>
    ) : (
        <>
            <div className="o-footer__sections-container">
                <div className="l-constrainer">
                    {sections}
                </div>
            </div>
            {bottom}
        </>
    );

    return (
        <footer className={fixed ? 'o-footer o-footer--fixed' : 'o-footer'} data-module="footer">
            <h1 className="u-sr-only">{title}</h1>
            {footerToTopButton}
            {footerContent}
        </footer>
    );
};

Footer.defaultProps = {
    title: '',
    fixed: false,
    toTopButtonLabel: 'nach oben',
};

export default Footer;
