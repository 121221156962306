import React from 'react';
import LinkList from '../link-list';
import { LinkProps } from '../../01-atoms/link';

export interface HeaderMetanavProps {
    metaNavLinks: LinkProps[]
}

const HeaderMetaNav = ({
    metaNavLinks,
}: HeaderMetanavProps) => (
    <div className="m-headerMetanav">
        <LinkList
            links={metaNavLinks}
            linkClasses={['m-headerMetanav__link']}
        />
    </div>
);

export default HeaderMetaNav;
