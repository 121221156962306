/* eslint-disable no-console */
class PdfPrinting {
    private debug= false;

    private PDFPRINTING_DEBUG_IDENTIFIER= 'PdfPrinting.js:';

    private pdfEndTriggered= false;

    private apps = {};

    private generateUUID = () => {
        let d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); // use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = (d + Math.random() * 16) % 16 || 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r && (0x3 || 0x8))).toString(16);
        });
    };

    public register = (): string => {
        const appId = this.generateUUID();
        this.apps[appId] = false;

        if (this.debug) {
            console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, 'Register new pdf application:', appId);
            console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, 'Registered pdf applications:', this.apps);
        }

        return appId;
    };

    private checkAppsAndCallback = () => {
        const appIds = Object.keys(this.apps);
        let appsDone = 0;

        for (const i in appIds) {
            if (Object.prototype.hasOwnProperty.call(appIds, i)) {
                const appId = appIds[i];
                if (typeof this.apps[appId] === 'boolean') {
                    appsDone += this.apps[appId] ? 1 : 0;
                }
            }
        }

        if (this.debug) {
            console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, appsDone, 'of', appIds.length, 'pdf apps done');
            console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, 'apps:', this.apps);
        }

        if (appsDone > appIds.length) {
            console.error('Unexpected behavior!');
            return false;
        }

        if (appsDone !== appIds.length) {
            return false;
        }

        if (this.pdfEndTriggered) {
            throw new Error('`wnvPdfConverter.startConversion()` already called!');
        }

        // @ts-ignore
        if (typeof wnvPdfConverter === 'object') {
            // @ts-ignore
            wnvPdfConverter.startConversion(); // eslint-disable-line
        }
        this.pdfEndTriggered = true;

        if (this.debug) {
            console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, 'Called `wnvPdfConverter.startConversion()`');
        }

        return true;
    };

    public onDone = appId => {
        if (typeof this.apps[appId] !== 'boolean') {
            return false;
        }

        this.apps[appId] = true;

        if (this.debug) {
            console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, 'App ended with the id:', appId);
        }

        this.checkAppsAndCallback();

        return true;
    };

    public onLoad = () => {
        if (this.pdfEndTriggered) {
            if (this.debug) {
                console.log(this.PDFPRINTING_DEBUG_IDENTIFIER, '`wnvPdfConverter.startConversion()` already called!');
            }

            return;
        }

        this.checkAppsAndCallback();
    };
}

export default PdfPrinting;
