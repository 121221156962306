const Comments = () => {
    function addCommentEventHandler() {
        const ERROR_DE = 'Bitte geben Sie einen Kommentar ein!';
        const ERROR_FR = 'Veuillez entrer un commentaire!';
        const ERROR_IT = 'Inserisci un Commento!';
        const lang = $('html').attr('lang');

        const formId = '#comment-form';
        const submitClass = '.js-comment-submit';
        const containerClass = '.js-comments-container';
        const bodyClass = '.js-comment-body';
        const topCommentListCls = '.js-comment-top-list';
        const allCommentListCls = '.js-comment-all-list';

        $(`${formId} ${submitClass}`).click(function (e) {
            e.preventDefault();

            // Find related DOM elements
            const $container = $(this).closest(containerClass);
            const $form = $container.find(formId);
            const $bodyInput = $form.find(bodyClass);
            const $topCommentList = $container.find(topCommentListCls);
            const $allCommentList = $container.find(allCommentListCls);
            const bodyInputValue = $bodyInput.val();

            if (Array.isArray(bodyInputValue) && bodyInputValue.length > 0) {
                switch (lang) {
                case 'de':
                    $form.attr('data-error', ERROR_DE);
                    break;
                case 'fr':
                    $form.attr('data-error', ERROR_FR);

                    break;
                case 'it':
                    $form.attr('data-error', ERROR_IT);
                    break;
                default:
                    return;
                }
            }

            $form.attr('data-error', '');

            // Disable form
            $bodyInput.prop('disabled', true);
            $form.attr('data-disabled', 'true');

            const action = $form.data('action');
            const body = $bodyInput.val();

            $.post(action, { body }, data => {
                $topCommentList.find('li:last-of-type').prependTo($allCommentList);

                $topCommentList.prepend($(data));

                $bodyInput.prop('disabled', false);
                $form.attr('data-disabled', 'false');
                $bodyInput.val('');
            });
        });
    }

    addCommentEventHandler();
};

export default Comments;
