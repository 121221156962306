import React, { useState } from 'react';
import Icon from '../../01-atoms/icon';
import { DropdownEntryProps } from '../header-actions-dropdown';
import CircleAvatar from '../../01-atoms/circle-avatar';

export interface UsermenuProps {
    isLoggedIn: boolean;
    loginUrl: string;
    loginLabel: string;
    username: string|null;
    usermenuActions: DropdownEntryProps[];
    amountOfHeaderActionsElements: number;
}

interface DropdownMenuProps {
    dropdownOpen: boolean;
    entries: DropdownEntryProps[];
    amountOfHeaderActionsElements: number;
}

interface DropdownMenuItemProps {
    baseClass: string;
    hasSectionDivider: boolean;
    label: string;
    url?: string|null;
    icon?: string|null;
}

interface DropdownToggleProps {
    open: boolean;
    username: string|null;
    onClick: () => void;
    maxNameLength: number;
    amountOfHeaderActionsElements: number;
}

const UsermenuDropdownToggle = ({
    open,
    username,
    onClick,
    maxNameLength,
    amountOfHeaderActionsElements,
}: DropdownToggleProps) => {
    const getInitals = () => {
        if (username === null) {
            return '';
        }

        return username.split(' ').map(x => x.charAt(0)).join('');
    };

    const trimUsername = () => {
        if (username === null) {
            return '';
        }

        if (username.length <= maxNameLength) {
            return username;
        }

        return `${username.substring(0, (maxNameLength - 3))}...`;
    };

    const baseClassName = 'm-usermenuDropdownToggle';
    const mobileLeftPositionClass = `-leftPosition_${amountOfHeaderActionsElements}`;

    return (
        <button type="button" className={`${baseClassName} ${mobileLeftPositionClass}`} aria-expanded={open} onClick={onClick}>
            <CircleAvatar initials={getInitals()} />
            <div className={`${baseClassName}__username`} aria-expanded={open}>
                <span>{trimUsername()}</span>
            </div>
            <div className={`${baseClassName}__toggleIndicator`} aria-expanded={open}>
                <Icon name="chevron-down" />
            </div>
        </button>
    );
};

const UsermenuDropdownItem = ({
    baseClass,
    hasSectionDivider,
    url,
    icon,
    label,
}: DropdownMenuItemProps) => (
    <li className={`${baseClass}__dropdownItem ${hasSectionDivider ? ' -sectionDivider' : ''}`}>
        <a href={url ?? ''}>
            <span className={`${baseClass}__dropdownItemIcon`}>
                {icon ? <Icon name={icon} /> : ''}
            </span>
            {label}
        </a>
    </li>
);

const UsermenuDropdown = ({
    entries,
    dropdownOpen,
    amountOfHeaderActionsElements,
}: DropdownMenuProps) => {
    let dropdownEntries;
    const baseClass = 'm-usermenuDropdown';

    if (entries.length > 0) {
        let previousSection = 0;
        dropdownEntries = entries.map(item => {
            let hasSectionDivider = false;
            if (previousSection === 0) {
                previousSection = item.section;
                hasSectionDivider = true;
            } else if (previousSection !== item.section) {
                previousSection = item.section;
                hasSectionDivider = true;
            }

            return (
                <UsermenuDropdownItem
                    baseClass={baseClass}
                    hasSectionDivider={hasSectionDivider}
                    url={item.url}
                    icon={item.icon}
                    label={item.label}
                    key={item.keyIdentifier}
                />
            );
        });
    }

    const mobileLeftPositionClass = `-leftPosition_${amountOfHeaderActionsElements}`;

    return (
        <ul className={`${baseClass} ${dropdownOpen ? '' : '-closed'} ${mobileLeftPositionClass}`}>
            {dropdownEntries}
        </ul>
    );
};

const UsermenuAvatar = ({
    isLoggedIn,
    loginUrl,
    loginLabel,
    username,
    usermenuActions,
    amountOfHeaderActionsElements,
}: UsermenuProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleChange = () => setDropdownOpen(!dropdownOpen);

    if (!isLoggedIn) {
        return (
            <a className="m-usermenuAvatar m-usermenuAvatar__anonymous" href={loginUrl}>
                <span>
                    <Icon name="user" />
                    <span>{loginLabel}</span>
                </span>
            </a>
        );
    }

    return (
        <div className={`m-usermenuAvatar ${dropdownOpen ? '-isOpen' : ''}`}>
            <UsermenuDropdown
                entries={usermenuActions}
                dropdownOpen={dropdownOpen}
                amountOfHeaderActionsElements={amountOfHeaderActionsElements}
            />
            <UsermenuDropdownToggle
                open={dropdownOpen}
                onClick={handleChange}
                username={username}
                maxNameLength={30}
                amountOfHeaderActionsElements={amountOfHeaderActionsElements}
            />
        </div>
    );
};

UsermenuAvatar.defaultProps = {
    username: null,
    usermenuActions: [],
};

UsermenuDropdownItem.defaultProps = {
    icon: null,
    url: null,
};

export default UsermenuAvatar;
