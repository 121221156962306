import React from 'react';

const CircleCross: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000" d="M12,2.4997 C17.247,2.4997 21.5,6.7527 21.5,11.9997 C21.5,17.2467 17.247,21.4997 12,21.4997 C6.753,21.4997 2.5,17.2467 2.5,11.9997 C2.5,6.7527 6.753,2.4997 12,2.4997 Z M7,16.9997 L17,6.9997 M17,16.9997 L7,6.9997" />
    </svg>
);

export default CircleCross;
