import React from 'react';

const Help = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fillRule="evenodd"
    >
        <path stroke="#000" d="M20.5,12.0002 C20.5,16.7122 16.713,20.5002 12,20.5002 C7.287,20.5002 3.5,16.7122 3.5,12.0002 C3.5,7.2872 7.287,3.5002 12,3.5002 C16.713,3.5002 20.5,7.2872 20.5,12.0002 Z"/>
        <path fill="#000" d="M11.124,17.991 L12.854,17.991 L12.854,16.325 L11.124,16.325 L11.124,17.991 Z M8.393,9.886 C8.43,8.894 8.625,8.125 8.978,7.58 C9.303,7.078 9.736,6.692 10.275,6.419 C10.814,6.147 11.42,6.011 12.093,6.011 C13.176,6.011 14.038,6.318 14.679,6.932 C15.276,7.498 15.576,8.242 15.576,9.166 C15.576,9.929 15.373,10.586 14.967,11.136 C14.786,11.387 14.351,11.848 13.662,12.521 C13.357,12.815 13.146,13.059 13.025,13.254 C12.905,13.449 12.821,13.69 12.773,13.979 C12.72,14.267 12.693,14.64 12.693,15.1 L11.332,15.1 C11.343,14.283 11.415,13.677 11.548,13.282 C11.644,12.993 11.782,12.735 11.96,12.505 C12.139,12.276 12.536,11.846 13.149,11.216 C13.545,10.81 13.804,10.445 13.926,10.119 C14.027,9.841 14.078,9.558 14.078,9.27 C14.078,8.736 13.932,8.288 13.638,7.925 C13.285,7.482 12.765,7.26 12.077,7.26 C10.651,7.26 9.882,8.135 9.771,9.886 L8.393,9.886 Z"/>
    </svg>

);

export default Help;
