import React, { useState } from 'react';
import AccordionBtn from '../accordion-btn';

export interface AccordionItem {
    title: string;
    children?: AccordionItem[] | null;
}

interface Props {
    active?: boolean;
    classes?: string[];
    elem?: AccordionItem
    id?: string;
    isList?: boolean;
    labelledby?: string;
}

const AccordionPanel: React.FC<Props> = ({
    children, id, labelledby, active, classes = [], isList = false, elem,
}) => {
    const cls = active ? 'a-accordion-panel a-accordion-panel--active' : 'a-accordion-panel';

    const [isActive, handleIsActive] = useState(false);

    function toggleAccordion() {
        handleIsActive(!isActive);
    }

    return (
        <>
            {isList && elem !== undefined ? (
                <li className={isActive ? 'mm-active' : ''}>
                    { elem.children ? (
                        <>
                            <AccordionBtn id="1" active={isActive} handleClick={toggleAccordion}>{ elem.title }</AccordionBtn>
                            <ul>
                                { elem.children && elem.children.map(item => (
                                    <AccordionPanel isList elem={item}>{ item.children }</AccordionPanel>
                                ))}
                            </ul>
                        </>
                    )
                        : (<span className="a-accordion-btn">{elem.title}</span>)}
                </li>
            ) : (
                <div
                    aria-hidden={!active}
                    id={`accordion-panel-${id}`}
                    aria-labelledby={labelledby}
                    className={`${cls} ${classes.join(' ')}`}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default AccordionPanel;
