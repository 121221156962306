import React from 'react';

const ChevronDown: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        className="a-icon a-icon--use-stroke"
        viewBox="0 0 24 24"
    >
        <polyline
            fill="none"
            stroke="#000"
            points="8.092 10.35 11.996 14.3 15.9 10.352"
        />
    </svg>
);

export default ChevronDown;
