import React from 'react';
import { render } from 'react-dom';
import Header from '../../ui/src/03-organisms/header/index';

document.addEventListener('DOMContentLoaded', () => {
    const headerWrapper = document.querySelector<HTMLElement>('.js-header-wrapper');
    if (headerWrapper === null) {
        return;
    }

    const isLoggedIn = headerWrapper.dataset.isLoggedIn;
    const metaNavLinks = JSON.parse(headerWrapper.dataset.metaNavLinks);
    const mainNavLinks = JSON.parse(headerWrapper.dataset.mainNavLinks);
    const languageConfig = JSON.parse(headerWrapper.dataset.languageConfig);
    const additionalActions = JSON.parse(headerWrapper.dataset.additionalActions);

    render(
        <React.StrictMode>
            {isLoggedIn === 'true'
                ? (
                    <Header
                        headerTriggerLabel={headerWrapper.dataset.headerTriggerLabel}
                        isLoggedIn
                        username={headerWrapper.dataset.username}
                        headerTitle={headerWrapper.dataset.headerTitle}
                        activeLangLabel={headerWrapper.dataset.activeLangLabel}
                        loginUrl={headerWrapper.dataset.loginUrl}
                        logoutUrl={headerWrapper.dataset.logoutUrl}
                        loginLabel={headerWrapper.dataset.loginLabel}
                        logoutLabel={headerWrapper.dataset.logoutLabel}
                        searchLabel={headerWrapper.dataset.searchLabel}
                        metaNavLinks={metaNavLinks}
                        mainNavTitle={headerWrapper.dataset.mainNavTitle}
                        mainNavLinks={mainNavLinks}
                        searchFormAction={headerWrapper.dataset.searchFormAction}
                        languageConfig={languageConfig}
                        additionalActions={additionalActions}
                        searchPlaceholder={headerWrapper.dataset.searchLabel}
                    />
                )
                : (
                    <Header
                        headerTriggerLabel={headerWrapper.dataset.headerTriggerLabel}
                        headerTitle={headerWrapper.dataset.headerTitle}
                        activeLangLabel={headerWrapper.dataset.activeLangLabel}
                        loginUrl={headerWrapper.dataset.loginUrl}
                        logoutUrl={headerWrapper.dataset.logoutUrl}
                        loginLabel={headerWrapper.dataset.loginLabel}
                        logoutLabel={headerWrapper.dataset.logoutLabel}
                        searchLabel={headerWrapper.dataset.searchLabel}
                        metaNavLinks={metaNavLinks}
                        mainNavTitle={headerWrapper.dataset.mainNavTitle}
                        mainNavLinks={mainNavLinks}
                        searchFormAction={headerWrapper.dataset.searchFormAction}
                        languageConfig={languageConfig}
                        additionalActions={additionalActions}
                        searchPlaceholder={headerWrapper.dataset.searchLabel}
                    />
                )}

        </React.StrictMode>,
        headerWrapper,
    );
});
