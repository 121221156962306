document.addEventListener('DOMContentLoaded', () => {
    const accordionTriggers = document.querySelectorAll<HTMLButtonElement>('.js-accordion-wid-trigger');
    if (accordionTriggers.length === 0) {
        return;
    }

    for (const trigger of Array.from(accordionTriggers) as HTMLElement[]) {
        trigger.addEventListener('click', event => {
            event.preventDefault();
            const open = trigger.getAttribute('aria-expanded') === 'true';
            const targetAccordionId = trigger.getAttribute('aria-target');
            if (targetAccordionId === null) {
                return;
            }

            const target = document.getElementById(targetAccordionId);
            if (open) {
                trigger.setAttribute('aria-expanded', 'false');
                target.style.display = 'none';
            } else {
                trigger.setAttribute('aria-expanded', 'true');
                target.style.display = 'block';
            }
        });
    }
});
