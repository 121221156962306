import React, { useReducer } from 'react';
import Icon from '../../01-atoms/icon';
import Tooltip from '../tooltip';
import reducer from '../tooltip/stories/reducer';
import { DEFAULT_STATE } from '../tooltip/stories/models';

export interface UsermenuProps {
    isLoggedIn: boolean;
    username?: string;
    loginUrl: string;
    loginLabel: string;
    logoutUrl: string;
    logoutLabel: string;
}

const Usermenu = ({
    isLoggedIn,
    username,
    loginUrl,
    loginLabel,
    logoutUrl,
    logoutLabel,
}: UsermenuProps) => {
    const tooltipContent = username ?? '';
    const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
    const tippyProps = {
        visible: state.visible,
        theme: 'userlogin',
    };

    const wrappedContentProps = {
        onMouseEnter: () => {
            showTooltip();
        },
        onMouseLeave: () => {
            hideTooltip();
        },
    };

    const showTooltip = () => {
        dispatch({
            type: 'SHOW_TOOLTIP',
        });
    };

    const hideTooltip = () => {
        dispatch({
            type: 'HIDE_TOOLTIP',
        });
    };

    if (isLoggedIn) {
        return (
            <a className="m-usermenu" href={logoutUrl}>
                <Tooltip
                    message={tooltipContent}
                    showCloseButton={false}
                    tippyProps={tippyProps}
                    wrappedContentProps={wrappedContentProps}
                >
                    <Icon name="user" />
                    <span className="m-usermenu__label">{logoutLabel}</span>
                </Tooltip>
            </a>
        );
    }

    return (
        <a className="m-usermenu" href={loginUrl}>
            <span>
                <Icon name="user" />
                <span className="m-usermenu__label">{loginLabel}</span>
            </span>
        </a>
    );
};

Usermenu.defaultProps = {
    username: null,
};

export default Usermenu;
