import {
    DEFAULT_STATE,
    HIDE_TOOLTIP,
    IState,
    SHOW_TOOLTIP,
    TooltipAction,
} from './models';

const reducer = (state = DEFAULT_STATE, action: TooltipAction): IState => {
    switch (action.type) {
    case SHOW_TOOLTIP:
        return {
            ...state,
            visible: true,
        };
    case HIDE_TOOLTIP:
        return {
            ...state,
            visible: false,
        };
    default:
        console.error('Action type not found');
        return {
            ...state,
        };
    }
};

export default reducer;
