import React, { ComponentProps } from 'react';
import Icon from '../icon';

export interface MetaLinkProps extends ComponentProps<'a'> {
    children?: React.ReactNode;
    classes?: string[];
    href?: string
    icon?: string;
    iconafter?: string;
    label?: string;
    onClick?: () => void;
    type?: 'button'|'reset'|'submit'|undefined;
}

const MetaLink = ({
    children,
    classes = [],
    href,
    icon,
    iconafter,
    label,
    onClick,
    type,
}: MetaLinkProps) => {
    const CustomTag = href ? 'a' : 'button';
    return (
        <CustomTag
            href={href}
            className={['a-meta-link', ...classes].join(' ')}
            onClick={onClick}
            type={type}
        >
            {
                icon
                    ? <Icon name={icon} /> : ''
            }
            <span className="a-meta-link__label">{label}</span>
            {
                iconafter
                    ? <Icon name={iconafter} /> : ''
            }
            {children && children}
        </CustomTag>
    );
};

MetaLink.defaultProps = {
    children: null,
    classes: [],
    href: null,
    icon: null,
    iconafter: null,
    label: null,
    onClick: null,
    type: undefined,
};

export default MetaLink;
