import React from 'react';

const Lock: React.FC = () => (
    <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        viewBox="0 0 24 24"
    >
        <path fill="none" stroke="#000" d="M9.5,9.4997 L9.5,5.9997 C9.5,4.6197 10.619,3.4997 12,3.4997 C13.381,3.4997 14.5,4.6197 14.5,5.9997 L14.5,9.4997 M12,16.9997 L12,12.9997 M6.5,19.5 L17.5,19.5 L17.5,9.5 L6.5,9.5 L6.5,19.5 Z" />
    </svg>
);

export default Lock;
