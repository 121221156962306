import React from 'react';

const Home = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fill="none" stroke="#000" d="M18.5,10.4997 L18.5,19.4997 L14.49,19.4997 L14.49,14.4997 L9.49,14.4997 L9.49,19.4997 L5.5,19.4997 L5.5,10.4997 M4,11.9997 L12,3.9997 L20,11.9997" />
    </svg>

);

export default Home;
