import React from 'react';
import { render } from 'react-dom';
import Button from '../../ui/src/01-atoms/button';

document.addEventListener('DOMContentLoaded', () => {
    const buttonWrappers = document.querySelectorAll<HTMLElement>('.js-button-wrapper');
    if (buttonWrappers.length === 0) {
        return;
    }

    for (const buttonWrapper of Array.from(buttonWrappers) as HTMLElement[]) {
        const type = buttonWrapper.dataset.type ?? 'button';
        const buttonType = buttonWrapper.dataset.buttonType;
        const buttonLabel = buttonWrapper.dataset.buttonLabel;
        const classes = [buttonWrapper.dataset.additionalClass];
        const hasArrows = buttonType === 'primary' || buttonType === 'secondary';
        const attrs = {};

        if (buttonType === 'primary') {
            classes.push('a-btn--primary');
        }

        if (buttonWrapper.dataset.visible !== null) {
            attrs['data-visible'] = buttonWrapper.dataset.visible ? 'true' : 'false';
        }

        if (buttonWrapper.dataset.name !== null) {
            attrs['name'] = buttonWrapper.dataset.name; // eslint-disable-line
        }

        const button = (
            // @ts-ignore
            <Button classes={classes} arrows={hasArrows} attrs={attrs} type={type}>
                {buttonLabel}
            </Button>
        );

        render(
            <React.StrictMode>{button}</React.StrictMode>,
            buttonWrapper,
        );
    }
});
