import React from 'react';

const HamburgerMenu: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fill="none" stroke="#000" d="M20,5.4997 L4,5.4997 M20,19.4997 L4,19.4997 M20,12.4997 L4,12.4997" />
    </svg>
);

export default HamburgerMenu;
