import React from 'react';
import Icon from '../../01-atoms/icon';

interface SidebarTriggerProps {
    handler: () => void,
    isOpen: boolean,
    titel: string
}

const HeaderSidebarTrigger: React.FC<SidebarTriggerProps> = props => (
    <div className="m-headerSidebarTrigger">
        <button type="button" data-open={props.isOpen} onClick={props.handler}>
            {
                props.isOpen
                    ? <Icon name="cross" /> : <Icon name="hamburger-menu" />
            }
            <span className="m-headerSidebarTrigger__label">{ props.titel }</span>
        </button>
    </div>
);

export default HeaderSidebarTrigger;
