import React, { useState } from 'react';

import HeaderActionsDropdownToggle from '../header-actions-dropdown-toggle';
import HeaderActionsDropdown, { DropdownEntryProps } from '../header-actions-dropdown';
import Icon from '../../01-atoms/icon';

interface LanguageSwitchProps {
    activeLangLabel: string;
    languageConfig: DropdownEntryProps[];
    isLanguageSwitch?: boolean;
    dropdownKeyAddition: string,
}

const LanguageSwitch = ({
    activeLangLabel,
    languageConfig,
    isLanguageSwitch,
    dropdownKeyAddition,
}: LanguageSwitchProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleChange = () => setDropdownOpen(!dropdownOpen);

    return (
        <>
            <div className="m-languageSwitch" data-lang-switch={isLanguageSwitch}>
                <HeaderActionsDropdownToggle
                    open={dropdownOpen}
                    icon="globe"
                    label={activeLangLabel}
                    onClick={handleChange}
                />
                <HeaderActionsDropdown
                    dropdownOpen={dropdownOpen}
                    entries={languageConfig}
                    dropdownKeyAddition={dropdownKeyAddition}
                />
                <div className="m-languageSwitch-sidebar">
                    <Icon name="globe" />
                    <select onChange={e => { window.location.assign(e.target.value); }} value={languageConfig.filter(lang => lang.label === activeLangLabel)[0].url}>
                        {languageConfig.map(lang => <option key={lang.keyIdentifier} value={lang.url}>{lang.label}</option>)}
                    </select>
                </div>
            </div>
        </>
    );
};

LanguageSwitch.defaultProps = {
    isLanguageSwitch: true,
};

export default LanguageSwitch;
