import React, { ComponentProps } from 'react';

import ArrowsCta from '../arrows-cta';
import Icon from '../icon';

export interface LinkProps extends ComponentProps<'a'> {
    href: string;
    attrs?: {};
    classes?: string[];
    icon?: string;
    iconsCTA?: string;
    children: LinkProps[]|string;
    isActiveLink?: boolean;
}

const Link = ({
    children,
    href,
    classes = [],
    attrs = {},
    icon,
    iconsCTA,
    isActiveLink = false,
}: LinkProps) => {
    const cls = ['a-link', isActiveLink ? 'a-link__activeLink' : '', ...classes];

    function iconSwitch(param: string) {
        switch (param) {
        case 'arrow':
            return <ArrowsCta icon="arrow-right">{children}</ArrowsCta>;
        case 'download':
            return <ArrowsCta icon="download">{children}</ArrowsCta>;
        default:
            return children;
        }
    }

    if (Array.isArray(children)) {
        const label = icon;
        console.log(children);
        return (
            <>
                <a href={href} className={cls.join(' ')}>
                    { label }
                </a>
                <div className="m-header-main-nav__subMenu">
                    <ul>
                        {children.map((child: LinkProps) => {
                            return (
                                <li
                                    key={encodeURIComponent(child.children.toString())}
                                    data-active={child.isActiveLink}
                                >
                                    <a href={child.href} className="m-header-main-nav__subMenuLink">
                                        { child.children }
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </>
        );
    }


    return (
        <a
            href={href}
            className={cls.join(' ')}
            {...attrs}
        >
            { icon ? <Icon name={icon} /> : '' }
            { iconsCTA ? iconSwitch(iconsCTA) : children }
        </a>
    );
};

Link.defaultProps = {
    classes: [],
    attrs: {},
    isActiveLink: false,
};

export default Link;
