import React, { useEffect, useReducer } from 'react';
import HeaderSidebar from '../../02-molecules/header-sidebar';
import useIsMobile from './useIsMobile';
import HeaderTop from '../header-top';
import HeaderBottom from '../header-bottom';
import reducer, {
    COLLAPSE_HEADER,
    INITIAL_STATE,
    TOGGLE_COLLAPSE_HEADER,
    TOGGLE_SIDEBAR,
    UNCOLLAPSE_HEADER,
} from './reducer';
import { DropdownEntryProps } from '../../02-molecules/header-actions-dropdown';
import { LinkProps } from '../../01-atoms/link';
import { MetaLinkProps } from '../../01-atoms/meta-link';

export interface HeaderProps {
    headerTriggerLabel: string
    activeLangLabel: string
    isLoggedIn: boolean,
    headerTitle: string;
    loginUrl: string;
    logoutUrl: string;
    loginLabel: string,
    logoutLabel: string,
    searchLabel: string,
    mainNavLinks: LinkProps[];
    mainNavTitle: string;
    metaNavLinks: LinkProps[];
    languageConfig?: DropdownEntryProps[]
    username?: string;
    usermenuActions?: DropdownEntryProps[]
    directSearchUrl?: string;
    searchFormAction?: string;
    searchPlaceholder: string;
    additionalActions?: MetaLinkProps[];
}

const Header = ({
    username,
    headerTitle,
    headerTriggerLabel,
    isLoggedIn,
    mainNavTitle,
    mainNavLinks,
    metaNavLinks,
    activeLangLabel,
    languageConfig,
    loginUrl,
    loginLabel,
    logoutUrl,
    logoutLabel,
    usermenuActions,
    directSearchUrl,
    searchFormAction,
    searchLabel,
    searchPlaceholder,
    additionalActions,
}: HeaderProps) => {
    const isMobile = useIsMobile();
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const collapseOffset = 150;
    const uncollapseOffset = 50;

    useEffect(() => {
        if (!isMobile) {
            window.addEventListener('scroll', scrollHandler, false);
        }
        return () => {
            if (!isMobile) {
                window.removeEventListener('scroll', scrollHandler, false);
            }
        };
    }, [isMobile]);

    function sidebarHandlerReact() {
        dispatch({
            type: TOGGLE_SIDEBAR,
        });
    }

    function collapseHandlerReact() {
        dispatch({
            type: TOGGLE_COLLAPSE_HEADER,
        });
    }

    const scrollHandler = function () {
        const html = document.querySelector('html');
        if (html !== null) {
            const scroll = html.scrollTop;
            if (scroll >= collapseOffset) {
                dispatch({
                    type: COLLAPSE_HEADER,
                });
            } else if (scroll <= uncollapseOffset) {
                dispatch({
                    type: UNCOLLAPSE_HEADER,
                });
            }
        }
    };

    return (
        <>
            <header className="o-header" data-module="header">
                <nav className="o-header__wrapper" data-collapsed={state.isCollapsed} data-clicked={state.isClicked}>
                    <h1 className="u-sr-only">{headerTitle}</h1>
                    <div className="o-header__skip-lines">
                        <ul className="list-unstyled" />
                    </div>
                    <div className="container-fluid">
                        <HeaderTop
                            headerTriggerLabel={headerTriggerLabel}
                            username={username}
                            usermenuActions={usermenuActions}
                            isClicked={state.isClicked}
                            activeLangLabel={activeLangLabel}
                            languageConfig={languageConfig}
                            isLoggedIn={isLoggedIn}
                            loginUrl={loginUrl}
                            loginLabel={loginLabel}
                            logoutUrl={logoutUrl}
                            logoutLabel={logoutLabel}
                            collapseHandlerReact={collapseHandlerReact}
                            sidebarHandlerReact={sidebarHandlerReact}
                            isSidebarOpen={state.isSidebarOpen}
                            metaNavLinks={metaNavLinks}
                            directSearchUrl={directSearchUrl}
                            searchFormAction={searchFormAction}
                            searchlabel={searchLabel}
                            searchPlaceholder={searchPlaceholder}
                            additionalActions={additionalActions}
                        />
                        <HeaderBottom
                            mainNavTitle={mainNavTitle}
                            mainNavLinks={mainNavLinks}
                        />
                    </div>
                </nav>
            </header>
            <HeaderSidebar
                isOpen={state.isSidebarOpen}
                handler={sidebarHandlerReact}
                mainNavLinks={mainNavLinks}
                mainNavTitle={mainNavTitle}
                metaNavLinks={metaNavLinks}
                activeLangLabel={activeLangLabel}
                languageConfig={languageConfig}
            />
        </>
    );
};

Header.defaultProps = {
    isLoggedIn: false,
    username: null,
    usermenuActions: [],
    directSearchUrl: null,
    searchFormAction: null,
    additionalActions: [],
};

export default Header;
