import React from 'react';
import LinkList from '../link-list';
import { LinkProps } from '../../01-atoms/link';

export interface HeaderMainnavProps {
    mainNavLinks: LinkProps[]
    mainNavTitle?: string
}

const HeaderMainnav: React.FC<HeaderMainnavProps> = ({
    mainNavTitle,
    mainNavLinks,
}) => (
    <nav className="m-header-main-nav" id="main-nav">
        <h2 className="sr-only">{mainNavTitle}</h2>
        <LinkList
            links={mainNavLinks}
            linkClasses={['m-header-mainnav-link']}
        />
    </nav>
);

export default HeaderMainnav;
