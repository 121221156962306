import React from 'react';
import Link, { LinkProps } from '../../01-atoms/link';

export interface LinkListProps {
    links: LinkProps[];
    classes?: string[];
    linkClasses?: string[];
}

const LinkList = ({
    links,
    classes = [],
    linkClasses = [],
}: LinkListProps) => (
    <ul className={`m-linkList ${classes.join(' ')}`}>
        {links && links.map(link => {
            const cls = link.classes ? link.classes : [];

            return (
                <li
                    className="m-linkList__item"
                    key={encodeURIComponent(link.children.toString())}
                    data-active={link.isActiveLink}
                >
                    <Link
                        href={link.href}
                        classes={[...linkClasses, ...cls]}
                        icon={link.icon}
                        attrs={link.attrs}
                        isActiveLink={link.isActiveLink}
                    >
                        {link.children}
                    </Link>
                </li>
            );
        })}
    </ul>
);

LinkList.defaultProps = {
    linkClasses: [],
    classes: [],
};

export default LinkList;
