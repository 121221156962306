const Cookies = {
    set(name: string, value: string, expiresInDays?: number) {
        let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; secure=true;`;
        if (expiresInDays) {
            const expiryDate = new Date();
            const expiresInMilliseconds = expiresInDays * 24 * 60 * 60 * 1000;
            expiryDate.setTime(expiryDate.getTime() + expiresInMilliseconds);
            cookie += ` expires='${expiryDate.toUTCString()}';`;
        }
        document.cookie = cookie;
    },
    get(name: string) {
        const nameString = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(nameString) === 0) {
                return c.substring(nameString.length, c.length);
            }
        }
        return '';
    },
    remove(name: string) {
        Cookies.set(name, '', -1);
    },
};

export default Cookies;
