import React from 'react';

const SignExclamationPoint: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fillRule="evenodd" d="M2.7998,20.4997 L20.1998,20.4997 L11.4998,2.9997 L2.7998,20.4997 Z M11.5,15.9997 L11.5,17.9997 M11.5,13.9997 L11.5,7.9997" />
    </svg>
);

export default SignExclamationPoint;
