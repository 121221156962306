import React from 'react';
import Icon from '../../01-atoms/icon';

export interface HeaderActionsDropdownProps {
    icon?: string;
    label?: string | null;
    maxLabelLength?: number;
    onClick: () => void;
    open: boolean;
}

const HeaderActionsDropdownToggle = ({
    icon,
    label = null,
    maxLabelLength = 30,
    onClick,
    open,
}: HeaderActionsDropdownProps) => {
    const trimLabel = () => {
        if (label === null) {
            return '';
        }

        if (label.length <= maxLabelLength) {
            return label;
        }

        return `${label.substring(0, (maxLabelLength - 3))}...`;
    };

    const baseClassName = 'm-headerActionsDropdownToggle';

    return (
        <button type="button" className={`${baseClassName} ${open ? '' : '-closed'}`} aria-expanded={open} onClick={onClick}>
            {icon ? <Icon name={icon} /> : '' }
            <div className={`${baseClassName}__label`}>
                <span>{trimLabel()}</span>
            </div>
            <div className={`${baseClassName}__toggleIndicator`} aria-expanded={open}>
                <Icon name="chevron-down" />
            </div>
        </button>
    );
};

HeaderActionsDropdownToggle.defaultProps = {
    icon: null,
    label: null,
    maxLabelLength: 30,
};

export default HeaderActionsDropdownToggle;
