import React, { ComponentProps } from 'react';
import Icon from '../../01-atoms/icon';

interface FooterToggleProps extends ComponentProps<'button'> {
    controls: string;
    text?: string;
    classes?: string[];
    expanded?: boolean;
    options?: object;
}

const FooterToggle = ({
    controls,
    text,
    expanded,
    options,
    classes = [],
}: FooterToggleProps) => (
    <button
        className={`a-footer-toggler ${classes.join(' ')}`}
        type="button"
        aria-controls={controls}
        aria-expanded={expanded}
        data-module="collapsible"
        data-collapsible-options={JSON.stringify(options)}
    >
        <span className="u-sr-only">{text}</span>
        <Icon name="chevron-up" />
        <p>{text}</p>
    </button>
);

FooterToggle.defaultProps = {
    classes: [],
    expanded: false,
    options: {
        activeCls: 'a-footer-toggler--active',
    },
    text: '',
};

export default FooterToggle;
