import React from 'react';
import HeaderMetaNav, { HeaderMetanavProps } from '../header-meta-nav';
import HeaderMainNav, { HeaderMainnavProps } from '../header-main-nav';
import LanguageSwitch from '../language-switch';
import { LinkProps } from '../../01-atoms/link';

interface SidebarProps extends HeaderMainnavProps, HeaderMetanavProps {
    mainNavLinks: LinkProps[]
    mainNavTitle?: string
    metaNavLinks: LinkProps[]
    activeLangLabel,
    languageConfig,
    handler: () => void,
    isOpen: boolean
}

const HeaderSidebar: React.FC<SidebarProps> = ({
    isOpen,
    mainNavTitle,
    mainNavLinks,
    metaNavLinks,
    activeLangLabel,
    languageConfig,
    handler,
}) => {
    const baseClass = 'm-headerSidebar';

    return (
        <>
            <div className={baseClass} data-open={isOpen}>
                <div className={`${baseClass}__mainNavWrapper`}>
                    <HeaderMainNav
                        mainNavLinks={mainNavLinks}
                        mainNavTitle={mainNavTitle}
                    />
                </div>
                <div className={`${baseClass}__bottomWrapper`}>
                    <div className={`${baseClass}__metaNavWrapper`}>
                        <HeaderMetaNav metaNavLinks={metaNavLinks} />
                    </div>
                    <div className={`${baseClass}__languageSwitchWrapper`}>
                        <LanguageSwitch
                            activeLangLabel={activeLangLabel}
                            languageConfig={languageConfig}
                            dropdownKeyAddition="headerSidebar"
                        />
                    </div>
                </div>
            </div>
            <div
                className={`${baseClass}__overlay`}
                data-open={isOpen}
                onClick={() => handler()}
                aria-hidden="true"
            />
        </>
    );
};

HeaderSidebar.defaultProps = {
    mainNavTitle: undefined,
};

export default HeaderSidebar;
