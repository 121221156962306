import React from 'react';
import FooterSection, { FooterSectionProps } from '../../02-molecules/footer-section';

interface FooterSectionsProps {
    footerSections: FooterSectionProps[]
}

const FooterSections = ({
    footerSections,
}: FooterSectionsProps) => (
    <div className="o-footer-sections">
        {footerSections.map(section => (
            <FooterSection
                id={section.id}
                title={section.title}
                text={section.text}
                link={section.link}
                socialLinks={section.socialLinks}
                linkList={section.linkList}
                key={`footerSection-${section.id}`}
            />
        ))}
    </div>
);
export default FooterSections;
