import React from 'react';
import { MetaLinkProps } from '../../01-atoms/meta-link';

interface HeaderActionsProps {
    classes?: string[]
    children: MetaLinkProps[]
}

const HeaderActions: React.FC<HeaderActionsProps> = ({ children, classes = [] }) => (
    // eslint-disable-next-line no-template-curly-in-string
    <div className="m-header-actions">
        <ul className={` ${classes.join(' ')}`}>
            {children && children.map((metalink, index) => {
                let isLanguageSwitch = false;
                // @ts-ignore
                if (typeof metalink.props !== 'undefined') {
                    // @ts-ignore
                    isLanguageSwitch = typeof metalink.props.isLanguageSwitch === 'boolean';
                }

                return (
                    <li key={`metalink-${index.toString()}`} className={`m-header-actions__item ${isLanguageSwitch ? '-isLanguageSwitch' : ''}`}>
                        {metalink}
                    </li>
                );
            })}
        </ul>
    </div>
);

export default HeaderActions;
