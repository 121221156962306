import React from 'react';
import Icon from '../../01-atoms/icon';

export interface DropdownEntryProps {
    children?: Element[];
    classes: String[];
    icon?: string;
    keyIdentifier?: string;
    label: string;
    onClick?: () => void;
    section: number;
    url?: string;
}

interface DropdownMenuProps {
    dropdownKeyAddition: string;
    dropdownOpen: boolean;
    entries: DropdownEntryProps[];
}

const HeaderActionsDropdownEntry = ({
    icon,
    label,
    onClick,
    url,
}: DropdownEntryProps) => {
    const baseClass = 'm-headerActionsDropdownEntry';
    const buttonInner = (
        <>
            {icon
                ? (<span className={`${baseClass}__icon`}><Icon name={icon} /></span>)
                : ''}
            <span className={`${baseClass}__dropdownlabel`}>{label}</span>
        </>
    );

    return (
        <li className={baseClass}>
            {onClick !== undefined
                ? (<button type="button" onClick={onClick}>{buttonInner}</button>)
                : (<a href={url}>{buttonInner}</a>)}
        </li>
    );
};

const HeaderActionsDropdown = ({
    entries = [],
    dropdownKeyAddition,
    dropdownOpen,
}: DropdownMenuProps) => {
    let dropdownEntries;
    const baseClass = 'm-headerActionsDropdown';

    if (entries.length > 0) {
        dropdownEntries = entries.map(item => (
            <HeaderActionsDropdownEntry
                section={item.section}
                keyIdentifier={item.keyIdentifier}
                label={item.label}
                classes={item.classes}
                url={item.url}
                onClick={item.onClick}
                icon={item.icon}
                key={`${item.keyIdentifier}-${dropdownKeyAddition}`}
            />
        ));
    }

    return (
        <ul className={`${baseClass} ${dropdownOpen ? '' : '-closed'}`}>
            {dropdownEntries}
        </ul>
    );
};

HeaderActionsDropdownEntry.defaultProps = {
    children: [],
    icon: null,
    keyIdentifier: null,
    url: null,
};

export default HeaderActionsDropdown;
