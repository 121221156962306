import toastr from 'toastr';

const Highlight = () => {
    const url = window.location.href;
    if (!url.includes('highlighted')) {
        return;
    }
    const paramVal = new URL(url).searchParams.get('highlighted');

    if (paramVal === '') {
        return;
    }

    const el = document.querySelector(`[data-identifier="${paramVal}"]`) as HTMLElement;

    if (el) {
        el.setAttribute('data-highlighted', 'true');
        el.scrollIntoView({ block: 'center' });
        el.focus();
    } else {
        toastr.options.closeButton = true;
        toastr.options.timeOut = 100000;
        toastr.warning(window.sv_resource.get('zafa_table_invalid_highlighted'));
    }
};

export default Highlight;
