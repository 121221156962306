import React from 'react';
import AccordionBtn from '../../01-atoms/accordion-btn';
import AccordionPanel from '../../01-atoms/accordion-panel';
import Link, { LinkProps } from '../../01-atoms/link';
import LinkList from '../link-list';
import SocialLinks from '../social-links';
import { ISocialLink } from '../../01-atoms/social-link';

export interface FooterSectionProps {
    id: string;
    link?: LinkProps,
    linkList: LinkProps[],
    socialLinks?: ISocialLink[],
    text?: string|null;
    title: string;
}

const FooterSection = ({
    id,
    link,
    linkList,
    socialLinks = [],
    text,
    title,
}: FooterSectionProps) => (
    <aside className="m-footer-section">
        <h3 className="m-footer-section__title">{title}</h3>
        <AccordionBtn classes={['a-accordion-btn--footer']} id={id}>
            {title}
        </AccordionBtn>
        <AccordionPanel classes={['a-accordion-panel--footer']} active id={id}>
            <div className="m-footer-section__body">
                {text && <p>{text}</p>}
                {link && (
                    <p>
                        <Link
                            href={link.href}
                            classes={link.classes}
                            iconsCTA={link.iconsCTA}
                            attrs={link.attrs}
                        >
                            {link.children}
                        </Link>
                    </p>
                )}
                {socialLinks.length > 0 && <SocialLinks socialLinks={socialLinks} />}
                {linkList && (
                    <LinkList
                        links={linkList}
                        linkClasses={['a-link--footer']}
                    />
                )}
            </div>
        </AccordionPanel>
    </aside>
);

FooterSection.defaultProps = {
    link: null,
    socialLinks: [],
    text: null,
};

export default FooterSection;
