import _ from 'underscore';

const Storage = {
    prefix: 'prefix_',
    debug: true,
    check() {
        return !_.isUndefined(localStorage);
    },
    get(key, hasPrefix) {
        if (!this.check()) {
            return null;
        }
        key = (!hasPrefix) ? this.getKey(key) : key;

        return this.onGet(localStorage.getItem(key));
    },
    getAll() {
        if (!this.check()) {
            return ({});
        }
        const self = this;

        return _.map(_.keys(localStorage), key => self.get(key, true));
    },
    set(key, value) {
        try {
            if (this.check()) {
                this.remove(key);
                localStorage.setItem(this.getKey(key), this.onSet(value));
            }
        } catch (e) {
            console.error(e);
        }
    },
    remove(key) {
        if (!this.check()) return;
        localStorage.removeItem(this.getKey(key));
    },
    removeByKeyContains(key) {
        if (!this.check()) return;

        _.each(_.keys(localStorage), function (e) {
            if (e.indexOf(key) >= 0) this.remove(e);
        }, this);
    },
    clear() {
        if (!this.check()) return;
        localStorage.clear();
    },
    onSet(value) {
        if (_.isFunction(value)) {
            return null;
        }

        return _.isObject(value) || _.isArray(value) ? JSON.stringify(value) : value;
    },
    onGet(value) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            if (this.debug) {
                console.error(e);
            }
        }
        return value;
    },
    getKey(key) {
        return this.prefix + (_.isArray(key) || _.isObject(key) ? key.join('.') : key);
    },
};

export default Storage;
