import React from 'react';

const ArrowRight: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M4,12.4914 L19.49,12.4914 M16.3486,15.6404 L19.4906,12.4914 L16.3486,9.3504" />
    </svg>
);

export default ArrowRight;
