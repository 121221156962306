import React from 'react';

const Exit: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M7,12.5002 L20.5,12.5002 M17.3584,15.6491 L20.5004,12.5001 L17.3584,9.3591 M15.5,16.9997 L15.5,19.4997 L3.5,19.4997 L3.5,4.4997 L15.5,4.4997 L15.5,6.9997" />
    </svg>
);

export default Exit;
