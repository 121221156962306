import React from 'react';
import Logo from '../../01-atoms/logo/index';

const HeaderBrand: React.FC = () => (
    <div className="m-header-brand">
        <a className="m-header-brand__link" href="/" aria-label="" aria-roledescription="link">
            <Logo name="standard" />
        </a>
    </div>
);

export default HeaderBrand;
