import Toastr from 'toastr';

const ESCAPE_KEY_CODE = '27';

class Initializer {
    private PdfPrinting;

    private $toTopButton = $('.js-to-top');

    constructor(PdfPrinting) {
        this.PdfPrinting = PdfPrinting;
    }

    private printPdfs() {
        const urls = [];

        $('*[data-check-me]:checked').each((index, element) => {
            urls.push(element.dataset.url);
        });

        let url = '/_common/pageprint';

        for (let i = 1; i < urls.length; i++) {
            const uriComponent = encodeURIComponent(urls[i]);
            url += i === 0
                ? `?url[]=${uriComponent}`
                : `&url[]=${uriComponent}`;
        }

        // Close printing window
        $('#printTables').prop('checked', false);

        Toastr.options.closeButton = true;
        Toastr.options.timeOut = 100000;
        Toastr.success(window.sv_resource.get('pdf_generation_success'));
        // location.href = newHref;
        const win = window.open(url, '_blank');
        setTimeout(() => {
            win.focus();
        }, 3000);
    }

    public initEvents() {
        this.$toTopButton.on('click', () => window.scrollTo(0, 0));

        const $print = $('*[data-print]');

        $(document).on('keydown', e => {
            if (e.key === ESCAPE_KEY_CODE) {
                $('#printTables').prop('checked', false);
            }
        });

        $('.js-print-close').on('click', () => {
            $('#printTables').prop('checked', false);
        });

        $('*[for="mnuAll"]').on('click', () => {
            $('*[data-check-me]').prop('checked', !$('#mnuAll').prop('checked'));
            if ($('*[data-check-me]:checked').length === 0) {
                $print.attr('data-disabled', 'true');
            } else {
                $print.attr('data-disabled', 'false');
            }
        });

        $('*[data-check-me]').on('change', () => {
            if ($('*[data-check-me]:checked').length === 0) {
                $print.attr('data-disabled', 'true');
            } else {
                $print.attr('data-disabled', 'false');
            }
        });

        $print.on('click', this.printPdfs);

        if ($('*[data-check-me]:checked').length === 0) {
            $print.attr('data-disabled', 'true');
        } else {
            $print.attr('data-disabled', 'false');
        }

        $(window).load(this.PdfPrinting.onLoad);
        $('.plf-zafa-table-init-warning').hide();
    }
}

export default Initializer;
