export interface IState {
    readonly visible: boolean,
}

export const DEFAULT_STATE: IState = {
    visible: false,
};

export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';

export type TooltipAction =
    | { type: typeof SHOW_TOOLTIP }
    | { type: typeof HIDE_TOOLTIP };
