import React from 'react';
import HeaderMainNav from '../../02-molecules/header-main-nav';
import { LinkProps } from '../../01-atoms/link';

interface HeaderBottomProps {
    mainNavLinks: LinkProps[]
    mainNavTitle?: string
}

const HeaderBottom = ({
    mainNavTitle,
    mainNavLinks,
}: HeaderBottomProps) => (
    <div className="o-header-bottom">
        <div className="o-header-bottom__mainnav">
            <HeaderMainNav
                mainNavTitle={mainNavTitle}
                mainNavLinks={mainNavLinks}
            />
        </div>
    </div>
);

HeaderBottom.defaultProps = {
    mainNavTitle: null,
};

export default HeaderBottom;
