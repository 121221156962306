import React, { ComponentProps } from 'react';
import Icon from '../icon';

export interface Cta extends ComponentProps<'button'>{
    classes?: string[];
    icon: string;
}

const ArrowCta: React.FC<Cta> = ({
    children,
    classes = [],
    icon,
}) => (
    <span className={`a-arrows-cta ${classes.join(' ')}`}>
        <Icon name={icon} />
        {children}
        <Icon name={icon} />
    </span>
);

export default ArrowCta;
