import React, { FC } from 'react';

import Logo from './logos/Logo';

const logos: {[id: string] : FC} = {
    standard: Logo,
};

const Icon: React.FC<{ name: string }> = ({ name }) => {
    const Log = logos[name];
    return <Log />;
};

export default Icon;
