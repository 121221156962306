import React from 'react';
import Icon from '../../01-atoms/icon';

interface CollapseTriggerProps {
    handler: () => void,
    isClicked: boolean,
    titel: string
}

const HeaderCollapseTrigger: React.FC<CollapseTriggerProps> = props => (
    <div>
        <div>
            <button type="button" onClick={props.handler}>
                {
                    props.isClicked
                        ? <Icon name="cross" /> : <Icon name="hamburger-menu" />
                }
                <span>{ props.titel }</span>
            </button>
        </div>
    </div>
);

export default HeaderCollapseTrigger;
