import React from 'react';

const ArrowUpCircle: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        aria-hidden="true"
        className="a-icon"
        viewBox="0 0 30.66 30.69"
    >
        <path d="M15.33,0A15.35,15.35,0,1,0,30.66,15.36h0A15.34,15.34,0,0,0,15.33,0Zm0,29.89A14.55,14.55,0,1,1,29.86,15.36h0A14.54,14.54,0,0,1,15.33,29.89Zm-4-15.71.77.76,2.65-2.65v8.64h1.09V12.32L18.53,15l.73-.8-4-3.84Z" />
    </svg>
);

export default ArrowUpCircle;
