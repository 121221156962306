import React from 'react';

const Trash: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="a-icon a-icon--use-stroke"
        focusable="false"
        aria-hidden="true"
    >
        <path
            d="M18.5,7.509 L18.5,19.5 L6.5,19.5 L6.5,7.509 M9.5,10.5002 L9.5,16.5002 M12.5,10.5002 L12.5,16.5002 M15.5,10.5002 L15.5,16.5002 M15.9502,7.509 C15.2592,6.314 13.9802,5.5 12.5002,5.5 C11.0192,5.5 9.7412,6.314 9.0502,7.509 M5,7.509 L20,7.509"
        />
    </svg>
);

export default Trash;
