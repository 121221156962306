import 'image-picker';

const WidgetPopup = () => {
    const SELECTOR_TABLE = '.js-table';
    const SELECTOR_ROW = '.js-table-row';
    const SELECTOR_META_ROW = '.js-meta-row';
    const SELECTOR_WIDGET_POPUP_TRIGGER = '.js-widget-popup-trigger';
    const SELECTOR_WIDGET_POPUP_OVERLAY = '.js-widget-popup-overlay';
    const SELECTOR_WIDGET_POPUP_OVERLAY_PAGE = '.js-widget-popup-overlay-page';
    const SELECTOR_WIDGET_POPUP_WRAPPER = '.js-widget-popup-overlay-wrapper';
    const SELECTOR_WIDGET_POPUP_OVERLAY_PAGE_NEXT = '.js-widget-popup-overlay-page-next';
    const SELECTOR_WIDGET_POPUP_OVERLAY_PAGE_PREVIOUS = '.js-widget-popup-overlay-page-previous';
    const SELECTOR_WIDGET_POPUP_CLOSE = '.js-widget-popup-overlay-close';
    const SELECTOR_WIDGET_INPUT_WIDGET_TYPE = 'input[name="widgetType"]';
    const SELECTOR_WIDGET_INPUT_WIDGET_TITLE = 'input[name="widgetTitle"]';
    const SELECTOR_YEAR_SELECTION_RADIO = '.js-zafa-widget-popup-radio';
    const SELECTOR_YEAR_SELECTION_CHECKBOX = '.js-zafa-widget-popup-checkbox';
    const SELECTOR_IMG_SELECTION = '.js-zafa-widget-popup-img-selection';
    const SELECTOR_EMBED_CODE = '.js-zafa-widget-embed-code';
    const SELECTOR_COPY_EMBED_CODE_BUTTON = '.js-zafa-widget-copy-embed-code';
    const SELECTOR_SCROLLABLE_CONTAINER = '.js-zafa-widget-scrollable-container';
    const SELECTOR_POPUP_ROW_TITLE = '.js-widget-popup-row-title';
    const SELECTOR_ROW_TITLE_CELL = 'td.js-table-cell.js-is-absolute[data-sort-order="0"]';

    const $widgetPopupTrigger = $(SELECTOR_WIDGET_POPUP_TRIGGER);
    const $widgetPopupOverlay = $(SELECTOR_WIDGET_POPUP_OVERLAY);
    const $widgetPopupOverlayPageNext = $(SELECTOR_WIDGET_POPUP_OVERLAY_PAGE_NEXT);
    const $widgetPopupOverlayPagePrevious = $(SELECTOR_WIDGET_POPUP_OVERLAY_PAGE_PREVIOUS);
    const $widgetPopupClose = $(SELECTOR_WIDGET_POPUP_CLOSE);
    const $widgetYearSelectionRadio = $(SELECTOR_YEAR_SELECTION_RADIO);
    const $widgetYearSelectionCheckbox = $(SELECTOR_YEAR_SELECTION_CHECKBOX);
    const $widgetEmbedCode = $(SELECTOR_EMBED_CODE);
    const $widgetCopyEmbedCodeButton = $(SELECTOR_COPY_EMBED_CODE_BUTTON);

    let activePage = 0;
    let $activeRow;

    const copyToClipboard = () => {
        $widgetEmbedCode.select();
        document.execCommand('copy');
    };

    const addPreviewWidget = ($elem, dataJson, scriptTag, widgetUrl, widgetStylesheetPath) => {
        $elem.append(`<a class="zafa-widget-link" href="${window.location.origin}">${window.location.hostname}</a>`);
        const $previewWidgetLink = $elem.find('.zafa-widget-link');
        $previewWidgetLink.attr('data-zafa-widget', dataJson);
        $previewWidgetLink.attr('data-widget-url', widgetUrl);
        $previewWidgetLink.attr('data-stylesheet-path', widgetStylesheetPath);
        $elem.append(scriptTag);
    };

    const generateWidgetEmbedCode = (dataJson, scriptTag, widgetUrl, widgetStylesheetPath) => {
        const linkClass = 'zafa-widget-link';
        const href = window.location.origin;
        const text = window.location.hostname;
        const linkTag = `<a class="${linkClass}" data-zafa-widget="${dataJson}" data-widget-url="${widgetUrl}" data-stylesheet-path="${widgetStylesheetPath}" href="${href}">${text}</a>`;

        return linkTag + scriptTag;
    };

    const generateScriptTag = () => {
        const widgetScriptPath = `${window.location.origin}/design/dist/widget.js`;

        return `<script class="js-zafa-widget-script" async="" src="${widgetScriptPath}"></script>`;
    };

    const setWidgetEmbedCode = () => {
        const $inputList = $widgetPopupOverlay.find(`${SELECTOR_WIDGET_POPUP_OVERLAY_PAGE} input:checked`);
        const $selectList = $widgetPopupOverlay.find(`${SELECTOR_WIDGET_POPUP_OVERLAY_PAGE} select`);
        const widgetType = $inputList.filter('[name="widgetType"]').val();
        const imgUrl = $selectList.filter('[name="widgetImage"]').val();
        const $years = $inputList.filter('[name="year[]"]').not('[data-active="false"]');

        let years = [];

        $years.each((index, year) => {
            years.push($(year).val());
        });

        if (years[0] === 'newest') {
            years = [];
        }

        const identifier = $activeRow.attr('data-identifier');
        const dataset = $activeRow.closest(SELECTOR_TABLE).attr('data-dataset-id');
        let title = $widgetPopupOverlay.find(SELECTOR_POPUP_ROW_TITLE).text();
        title = escapeHtml(title);

        if (!years) {
            years = [];
        }
        const data = {
            widgetType,
            dataset,
            identifier,
            years,
            imgUrl,
            title,
        };
        let dataJson = JSON.stringify(data);
        dataJson = dataJson.replace(/"/g, '\'');

        const plfZafaHead = document.querySelector<HTMLElement>('.plf-zafa-head');
        const widgetUrl = `${window.location.origin}${plfZafaHead.dataset.widgetUrl}/initWidgetInstance`;
        const widgetStylesheetPath = `${window.location.origin}/design/dist/widget.css`;
        const output = document.querySelector<HTMLTextAreaElement>(`${SELECTOR_WIDGET_POPUP_OVERLAY} ${SELECTOR_WIDGET_POPUP_OVERLAY_PAGE} textarea[name="embedCode"]`);

        const $wrapper = $widgetPopupOverlay.find(SELECTOR_WIDGET_POPUP_WRAPPER);
        $wrapper.empty();

        const scriptTag = generateScriptTag();
        addPreviewWidget($wrapper, dataJson, scriptTag, widgetUrl, widgetStylesheetPath);
        output.value = generateWidgetEmbedCode(dataJson, scriptTag, widgetUrl, widgetStylesheetPath);
    };

    const setDataVisible = (elements: HTMLElement | NodeListOf<HTMLElement>, state: string) => {
        if (elements instanceof HTMLElement) {
            elements.dataset.visible = state;

            return;
        }

        for (const element of Array.from(elements) as HTMLElement[]) {
            element.dataset.visible = state;
        }
    };

    const setPageDataVisible = (pageNr, visible) => {
        const selectedPage = document.querySelector<HTMLDivElement>(`${SELECTOR_WIDGET_POPUP_OVERLAY_PAGE}[data-page-nr="${pageNr}"]`);
        setDataVisible(selectedPage, visible);
    };

    const togglePageDataVisible = pageNr => {
        const selectedPage = document.querySelector<HTMLDivElement>(`${SELECTOR_WIDGET_POPUP_OVERLAY_PAGE}[data-page-nr="${pageNr}"]`);
        toggleVisible(selectedPage);
    };

    const toggleVisible = selectedPage => {
        if (selectedPage.dataset.visible !== 'false') {
            setDataVisible(selectedPage, 'false');
        } else {
            setDataVisible(selectedPage, 'true');
        }
    };

    const generateWidgetOverlay = event => {
        event.stopPropagation();
        const title = getRowTitle();
        $widgetPopupOverlay.find(SELECTOR_POPUP_ROW_TITLE).text(title);
        $widgetPopupOverlay.find(SELECTOR_WIDGET_INPUT_WIDGET_TITLE).val(title);
        $widgetPopupOverlay.attr('data-visible', 'true');
        setPageDataVisible(activePage, true);
    };

    const hasPage = (pageNr, zafaWidgetPopupCtrls: HTMLDivElement) => {
        const page = getPage(zafaWidgetPopupCtrls, pageNr);

        return page !== null;
    };

    const doSkipPage = (zafaWidgetPopupCtrls: HTMLDivElement, pageNr) => {
        const page = getPage(zafaWidgetPopupCtrls, pageNr);

        return page.dataset.skipPage === 'true';
    };

    const getPage = (zafaWidgetPopupCtrls: HTMLDivElement, pageNr): HTMLDivElement => {
        const scrollableContainer = zafaWidgetPopupCtrls.querySelector<HTMLDivElement>(SELECTOR_SCROLLABLE_CONTAINER);

        return scrollableContainer.querySelector<HTMLDivElement>(`${SELECTOR_WIDGET_POPUP_OVERLAY_PAGE}[data-page-nr="${pageNr}"]`);
    };

    const changePage = (direction: number, button: HTMLButtonElement) => {
        const nextPage = activePage + direction;
        const zafaWidgetPopupCtrls = button.parentElement.parentElement as HTMLDivElement;

        if (!hasPage(nextPage, zafaWidgetPopupCtrls)) { // this should never happen
            setDataVisible(button, 'false');
            return;
        }
        togglePageDataVisible(activePage);
        togglePageDataVisible(nextPage);
        activePage = nextPage;

        if (!hasPage(nextPage + direction, zafaWidgetPopupCtrls)) {
            setDataVisible(button, 'false');
        }
        if (direction > 0) {
            setDataVisible(zafaWidgetPopupCtrls.querySelectorAll(SELECTOR_WIDGET_POPUP_OVERLAY_PAGE_PREVIOUS), 'true');
        } else {
            setDataVisible(zafaWidgetPopupCtrls.querySelectorAll(SELECTOR_WIDGET_POPUP_OVERLAY_PAGE_NEXT), 'true');
        }

        if (doSkipPage(zafaWidgetPopupCtrls, activePage)) {
            changePage(direction, button);
        }
    };

    const pageNext = event => {
        const continueButton = event.currentTarget;
        displayInputsChoiceBased();
        changePage(+1, continueButton);
        if (!hasPage(activePage + 1, continueButton.parentElement.parentElement)) {
            setWidgetEmbedCode();
        }
    };

    const pagePrevious = event => {
        changePage(-1, event.currentTarget);
    };

    const widgetOverlayClose = () => {
        $widgetPopupOverlay.attr('data-visible', 'false');
        $(document).unbind('keydown');
    };

    const displayInputsChoiceBased = () => {
        const selectedWidgetType = $widgetPopupOverlay.find(`${SELECTOR_WIDGET_INPUT_WIDGET_TYPE}:checked`).val();
        if (selectedWidgetType === 'banner') {
            $widgetYearSelectionRadio.attr('data-visible', 'true');
            $widgetYearSelectionRadio.find('input').attr('data-active', 'true');
            $widgetYearSelectionCheckbox.attr('data-visible', 'false');
            $widgetYearSelectionCheckbox.find('input').attr('data-active', 'false');
            $('.banner-img').show();
            $('.chart-img').hide();
            $('.banner-txt').show();
            $('.chart-txt').hide();
            $widgetPopupOverlay.find(SELECTOR_IMG_SELECTION).closest(SELECTOR_WIDGET_POPUP_OVERLAY_PAGE).attr('data-skip-page', 'false');
        } else {
            $widgetYearSelectionCheckbox.attr('data-visible', 'true');
            $widgetYearSelectionCheckbox.find('input').attr('data-active', 'true');
            $widgetYearSelectionRadio.attr('data-visible', 'false');
            $widgetYearSelectionRadio.find('input').attr('data-active', 'false');
            $('.chart-img').show();
            $('.banner-img').hide();
            $('.chart-txt').show();
            $('.banner-txt').hide();
            $widgetPopupOverlay.find(SELECTOR_IMG_SELECTION).closest(SELECTOR_WIDGET_POPUP_OVERLAY_PAGE).attr('data-skip-page', 'true');
        }
    };

    const getRowTitle = () => $activeRow.find(SELECTOR_ROW_TITLE_CELL).attr('data-cell-value');

    const updateTitle = event => $widgetPopupOverlay.find(SELECTOR_POPUP_ROW_TITLE).text(event.currentTarget.value);

    const escapeHtml = text => {
        const map = {
            '"': '&quot;',
            "'": '&#039;',
        };

        return text.replace(/["']/g, m => map[m]);
    };

    const getActiveRow = elem => {
        $activeRow = elem.closest(SELECTOR_ROW);
        if ($activeRow.length < 1) {
            const $activeMetaRow = elem.closest(SELECTOR_META_ROW);
            const $dataId = $activeMetaRow.data('id').split('-')[0];
            $activeRow = $activeMetaRow.siblings(`${SELECTOR_ROW}[data-id="${$dataId}"]`);
        }
        return $activeRow;
    };

    const init = () => {
        $widgetPopupTrigger.on('click', event => {
            $activeRow = getActiveRow($(event.currentTarget));
            generateWidgetOverlay(event);
        });
        $widgetPopupOverlayPageNext.on('click', pageNext);
        $widgetPopupOverlayPagePrevious.on('click', pagePrevious);
        $widgetPopupClose.on('click', widgetOverlayClose);
        $widgetCopyEmbedCodeButton.on('click', copyToClipboard);
        $widgetPopupOverlay.find(SELECTOR_WIDGET_INPUT_WIDGET_TITLE).on('change', updateTitle);

        // @ts-ignore
        $widgetPopupOverlay.find('select').imagepicker();
    };

    init();
};

export default WidgetPopup;
