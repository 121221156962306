import moment from 'moment';

export function initRelativeDates(containerNode: Element): void {
    containerNode.querySelectorAll('.js-relative-date').forEach(node => {
        initRelativeDate(node);
    });
}

export function observeRelativeDateContainers(): void {
    const containers = document.querySelectorAll('.js-relative-date-container');
    containers.forEach(container => {
        observeRelativeDateContainer(container);
    });
}

function initRelativeDate(node: Element): void {
    moment.locale(getLocale());
    node.textContent = moment(node.textContent).fromNow();
    node.classList.remove('js-relative-date');
}

function observeRelativeDateContainer(container: Element): void {
    const observer = new MutationObserver((() => {
        initRelativeDates(container);
    }));

    observer.observe(container, {
        attributes: true,
        childList: true,
        subtree: true,
    });
}

function getLocale(): string {
    return document.querySelector<HTMLHtmlElement>('html').dataset.lang;
}
