import React from 'react';

interface CircleAvatarProps {
    initials: string;
}

const CircleAvatar = ({
    initials,
}: CircleAvatarProps) => (
    <div className="a-circleAvatar">
        <span className="a-circleAvatar__initials">{initials}</span>
    </div>
);

export default CircleAvatar;
