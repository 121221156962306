/* eslint-disable import/first */
/* eslint-disable import/order */
import '../scss/app.scss';

const $ = require('jquery');

window.$ = $;
window.jQuery = $;
// @ts-ignore
global.$ = $;
// @ts-ignore
global.jQuery = $;

import 'jquery-migrate';
import 'picturefill';
import 'jquery-touch-events';
import 'chart.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/carousel';

// view
import PdfPrinting from './app/helpers/pdfPrinting';
import Storage from './app/helpers/localStorage';
import Comments from './app/components/comments';
import Sidebar from './app/components/sidebar';
import LangSelect from './app/components/langSelect';
import SocialShare from './app/components/socialShare';
import Slider from './app/components/slider';
import WidgetPopup from './app/components/widgetPopup';
import UserSurvey from './app/components/userSurvey';
import Initialize from './app/components/initializer';
import ZaFaTable from './app/components/zaFaTable';
import Highlight from './app/components/highlight';
import DeepLink, { copyToClipboard } from './app/components/deepLink';

// widgets
import '../../../platform/widgets/view/accordion/js/accordion';
import '../../../platform/widgets/view/search/js/loadMore';

// view modules
import initSysteminfo from '../../../platform/modules/common/systeminfo/ts/systeminfo';

// storybook
import '../templates/components/header/header';
import '../templates/components/footer/footer';
import '../templates/components/button/button';
import '../templates/ui/src/js/index';

declare global {
    interface Window {
        jQuery: typeof $,
        $: typeof $,
        // @ts-ignore
        sv_resource: typeof SVResource,
        Optanon: any
    }

    interface JQueryStatic {
        migrateMute: any
    }

    interface SVResource {
        get: (key: string|number) => string
    }

    interface Document {
        documentMode?: number
    }

    // @ts-ignore
    const sv_resource: SVResource; // eslint-disable-line
}

window.$ = $;
window.jQuery = $;
$.migrateMute = true;

document.addEventListener('DOMContentLoaded', () => {
    const PdfPrinter = new PdfPrinting();
    const Initializer = new Initialize(PdfPrinter);
    const TableService = new ZaFaTable(Storage, PdfPrinter);
    TableService.initialize();
    Sidebar();
    LangSelect();
    Comments();
    SocialShare();

    // has been deactivated with T-9428
    // import HelpTables from './app/components/helpTables';
    // HelpTables();

    Slider();
    WidgetPopup();
    UserSurvey();
    Initializer.initEvents();

    initSysteminfo();

    document.querySelectorAll('.js-deeplink-trigger').forEach(el => {
        DeepLink(el);
    });

    document.querySelectorAll('.js-deeplink-copy-no-overlay').forEach(el => {
        el.addEventListener('click', copyToClipboard);
    });
});

window.addEventListener('load', () => {
    Highlight();
});
