import React from 'react';

const User: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M10.084,11.697 C7.144,12.757 5.5,16.25 5.5,19.5 L18.5,19.5 C18.5,16.23 16.852,12.747 13.912,11.697 M12,5.4997 C13.657,5.4997 15,7.0667 15,8.9997 C15,10.9327 13.657,12.4997 12,12.4997 C10.343,12.4997 9,10.9327 9,8.9997 C9,7.0667 10.343,5.4997 12,5.4997 Z" />
    </svg>
);

export default User;
