import React from 'react';

import LinkList from '../../02-molecules/link-list';
import { LinkProps } from '../../01-atoms/link';

interface FooterBottomProps {
    footerBottomLinks: LinkProps[];
}

const FooterBottom = ({
    footerBottomLinks,
}: FooterBottomProps) => (
    <div className="o-footer-bottom">
        <div className="container-fluid">
            <LinkList
                classes={['m-linkList--footer-bottom']}
                links={footerBottomLinks}
                linkClasses={['a-link--footer-bottom']}
            />
        </div>
    </div>
);

export default FooterBottom;
