const LangSelect = () => {
    $('.js-langswitch a').on('click', event => {
        event.preventDefault();

        const rel = $(this).data('id');
        if (typeof rel === 'number') {
            const form = $('<form/>').attr({ method: 'post', action: document.location });
            $('<input type="hidden"/>').attr('name', 'sv_lang').val(rel).appendTo(form);
            $('<input type="hidden"/>').attr('name', 'sv_lang_change').val('true').appendTo(form);
            form.appendTo('body').submit();
        }
    });
};

export default LangSelect;
