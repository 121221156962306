import React, { ComponentProps, ReactElement } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import Icon from '../../01-atoms/icon';
import Button from '../../01-atoms/button';

export interface ITooltip {
    classes?: string[],
    handleCloseClick?: () => void,
    message: ReactElement | string
    showCloseButton?: boolean,
    tippyProps?: TippyProps,
    wrappedContentProps?: ComponentProps<'span'>
}

const Tooltip: React.FC<ITooltip> = props => {
    const {
        classes = [], children, handleCloseClick, message, showCloseButton, tippyProps, wrappedContentProps,
    } = props;

    const cls = ['m-tooltip', ...classes];

    if (showCloseButton) {
        cls.push('m-tooltip--with-icon');
    }

    if (typeof tippyProps !== 'undefined' && (tippyProps.placement === 'left' || tippyProps.placement === 'right')) {
        console.error('Not implemented');
    }

    return (
        <Tippy
            content={(
                <div className={cls.join(' ')} role="tooltip" dir="ltr">
                    <div className="m-tooltip-content">
                        {message}
                    </div>
                    {showCloseButton
                    && (
                        <Button classes={['a-btn--icon a-btn--close']} handleClick={handleCloseClick}>
                            <Icon
                                name="circle-cross"
                            />
                        </Button>
                    )}
                </div>
            )}
            {...tippyProps}
        >
            <span {...wrappedContentProps}>{children}</span>
        </Tippy>
    );
};

export default Tooltip;
