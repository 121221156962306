import React from 'react';

const Download: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fill="none" stroke="#000" d="M4.5,14.9997 L4.5,17.5097 L18.5,17.5097 L18.5,15.0097 M7.5923,11.3547 L11.5003,15.2897 L15.4043,11.3547 M11.5,6.9987 L11.5,15.2897" />
    </svg>
);

export default Download;
