import React from 'react';
import HeaderBrand from '../../02-molecules/header-brand';
import HeaderActions from '../../02-molecules/header-actions';
import HeaderMetaNav from '../../02-molecules/header-meta-nav';
import Usermenu from '../../02-molecules/usermenu';
import HeaderCollapseTrigger from '../../02-molecules/header-collapse-trigger';
import HeaderSidebarTrigger from '../../02-molecules/header-sidebar-trigger';
import LanguageSwitch from '../../02-molecules/language-switch';
import { DropdownEntryProps } from '../../02-molecules/header-actions-dropdown';
import UsermenuAvatar from '../../02-molecules/usermenu-avatar';
import { LinkProps } from '../../01-atoms/link';
import HeaderSearchForm from '../../02-molecules/header-search-form';
import MetaLink, { MetaLinkProps } from '../../01-atoms/meta-link';

interface HeaderTopProps {
    headerTriggerLabel: string,
    activeLangLabel: string,
    collapseHandlerReact: () => void,
    isClicked: boolean,
    isLoggedIn: boolean,
    isSidebarOpen: boolean,
    sidebarHandlerReact: () => void,
    loginUrl: string;
    loginLabel: string;
    logoutUrl: string;
    logoutLabel: string;
    searchlabel: string;
    languageConfig?: DropdownEntryProps[];
    metaNavLinks?: LinkProps[],
    username?: string,
    directSearchUrl?: string;
    searchFormAction?: string;
    searchPlaceholder: string;
    usermenuActions?: DropdownEntryProps[];
    additionalActions?: MetaLinkProps[];
}

const HeaderTop = ({
    headerTriggerLabel,
    username,
    isClicked,
    activeLangLabel,
    isLoggedIn,
    collapseHandlerReact,
    sidebarHandlerReact,
    isSidebarOpen,
    languageConfig,
    metaNavLinks,
    loginUrl,
    loginLabel,
    logoutUrl,
    logoutLabel,
    usermenuActions,
    directSearchUrl,
    searchFormAction,
    searchlabel,
    searchPlaceholder,
    additionalActions,
}: HeaderTopProps) => {
    let amountOfHeaderActionsElements = (searchFormAction !== null) ? 1 : 0;
    if (additionalActions !== undefined) {
        amountOfHeaderActionsElements += additionalActions.length;
    }
    const baseClass = 'o-header-top';

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__left`}>
                <div className={`${baseClass}__collapse-trigger`}>
                    <HeaderCollapseTrigger
                        handler={collapseHandlerReact}
                        isClicked={isClicked}
                        titel={headerTriggerLabel}
                    />
                </div>
                <div className={`${baseClass}__sidebar-trigger`}>
                    <HeaderSidebarTrigger
                        isOpen={isSidebarOpen}
                        handler={sidebarHandlerReact}
                        titel={headerTriggerLabel}
                    />
                </div>
                <div className={`${baseClass}__metaNav`}>
                    <HeaderMetaNav
                        metaNavLinks={metaNavLinks ?? []}
                    />
                </div>
            </div>
            <div className={`${baseClass}__right`}>
                <div className={`${baseClass}__actions`}>
                    <HeaderActions>
                        {searchFormAction !== null || directSearchUrl !== null
                            ? (
                                <HeaderSearchForm
                                    searchLabel={searchlabel}
                                    directSearchUrl={directSearchUrl}
                                    searchFormAction={searchFormAction}
                                    placeholder={searchPlaceholder}
                                />
                            )
                            : ('')}
                        {additionalActions !== undefined && additionalActions.length > 0
                            ? (additionalActions.map((link, index) => (
                                <div className="m-header-actions__additionalItem" key={`headerActionsIndex_${index.toString()}`}>
                                    <MetaLink
                                        href={link.href}
                                        icon={link.icon}
                                        label={link.label}
                                        key={`headerActionsIndex_${index.toString()}`}
                                    />
                                </div>
                            )))
                            : ('')}
                        {usermenuActions !== undefined && usermenuActions.length > 0
                            ? (
                                <UsermenuAvatar
                                    isLoggedIn={isLoggedIn}
                                    loginUrl={loginUrl}
                                    loginLabel={loginLabel}
                                    username={username}
                                    usermenuActions={usermenuActions}
                                    amountOfHeaderActionsElements={amountOfHeaderActionsElements}
                                />
                            )
                            : (
                                <Usermenu
                                    isLoggedIn={isLoggedIn}
                                    username={username}
                                    loginUrl={loginUrl}
                                    loginLabel={loginLabel}
                                    logoutUrl={logoutUrl}
                                    logoutLabel={logoutLabel}
                                />
                            )}
                        {languageConfig !== null && languageConfig !== undefined
                            ? (
                                <LanguageSwitch
                                    activeLangLabel={activeLangLabel}
                                    languageConfig={languageConfig}
                                    dropdownKeyAddition="headerTop"
                                />
                            )
                            : ('')}
                    </HeaderActions>
                </div>
                <div className={`${baseClass}__brand-container`}>
                    <HeaderBrand />
                </div>
            </div>
        </div>
    );
};

HeaderTop.defaultProps = {
    metaNavLinks: [],
    username: null,
    usermenuActions: [],
    directSearchUrl: null,
    searchFormAction: null,
    additionalActions: [],
    languageConfig: null,
};

export default HeaderTop;
