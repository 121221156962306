import React from 'react';

const ToggleOff: React.FC = () => (
    <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className="a-icon__colored a-icon__toggleOn"
        viewBox="0 0 40 40"
    >
        <path fill="#98ccfd" d="M11,30.5C5.21,30.5,0.5,25.79,0.5,20S5.21,9.5,11,9.5h18c5.79,0,10.5,4.71,10.5,10.5 S34.79,30.5,29,30.5H11z" />
        <path fill="#98ccfd" d="M29,10c5.514,0,10,4.486,10,10s-4.486,10-10,10H11C5.486,30,1,25.514,1,20s4.486-10,10-10H29 M29,9 H11C4.925,9,0,13.925,0,20v0c0,6.075,4.925,11,11,11h18c6.075,0,11-4.925,11-11v0C40,13.925,35.075,9,29,9L29,9z" />
        <path fill="#fff" d="M29 12.5A7.5 7.5 0 1 0 29 27.5A7.5 7.5 0 1 0 29 12.5Z" />
    </svg>
);

export default ToggleOff;
