import React from 'react';

const Globe: React.FC = () => (
    <svg
        focusable="false"
        aria-hidden="true"
        className="a-icon a-icon--use-stroke"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            fill="none"
            stroke="#000"
            d="M19.5,11.5002 C19.5,7.0822 15.918,3.5002 11.5,3.5002 C7.082,3.5002 3.5,7.0822 3.5,11.5002 C3.5,15.9182 7.082,19.5002 11.5,19.5002 C15.918,19.5002 19.5,15.9182 19.5,11.5002 Z M18.5,15.529 L4.5,15.529 M19.5,11.529 L3.5,11.529 M18.5,7.529 L4.5,7.529 M8.8696,19.0486 C7.3496,17.2356 6.3826,14.5286 6.3826,11.5006 C6.3826,8.4716 7.3496,5.7646 8.8696,3.9516 M8.8696,3.9519 C7.3496,5.7639 6.3826,8.4719 6.3826,11.4999 C6.3826,14.5289 7.3496,17.2349 8.8696,19.0489 M14.1309,3.9519 C15.6509,5.7639 16.6169,8.4719 16.6169,11.4999 C16.6169,14.5289 15.6509,17.2349 14.1309,19.0489 M14.1309,19.0486 C15.6509,17.2356 16.6169,14.5286 16.6169,11.5006 C16.6169,8.4716 15.6509,5.7646 14.1309,3.9516 M11.5,3.5002 L11.5,19.5002"
        />
    </svg>
);

export default Globe;
