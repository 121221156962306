import React from 'react';
import Icon from '../../01-atoms/icon';
import Button from '../../01-atoms/button';

interface ToTopLabelProps {
    label?: string;
}

const ToTopLabel = ({
    label,
}: ToTopLabelProps) => {
    const baseClass = 'm-toTopLabel';

    return (
        <Button classes={[baseClass, 'js-to-top']} attrs={{ 'data-module': 'to-top' }}>
            <div className={`${baseClass}__content`}>
                <span className="hidden-xs hidden-sm">{label}</span>
                <span className="u-sr-only">{label}</span>
                <Icon name="chevron-up" />
            </div>
        </Button>
    );
};

ToTopLabel.defaultProps = {
    label: null,
};

export default ToTopLabel;
