import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../../01-atoms/text-input';
import MetaLink from '../../01-atoms/meta-link';

interface HeaderSearchFormProps {
    directSearchUrl?: string;
    searchFormAction?: string;
    searchLabel: string;
    placeholder?: string;
}

const useComponentVisible = initialIsVisible => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = event => {
        // @ts-ignore
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
};

const useFocus = () => {
    const htmlElRef = useRef(null);
    // @ts-ignore
    const setFocus = () => htmlElRef.current && htmlElRef.current.focus();

    return [htmlElRef, setFocus];
};

const HeaderSearchForm = ({
    directSearchUrl,
    searchFormAction,
    searchLabel,
    placeholder,
}: HeaderSearchFormProps) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [inputRef, setInputFocus] = useFocus();

    const handleChange = () => {
        setIsComponentVisible(!isComponentVisible);
        // @ts-ignore
        setInputFocus();
    };

    if (searchFormAction === null && directSearchUrl === null) {
        throw new Error('Either a direct search URL or the search form action must be defined');
    }

    if (directSearchUrl !== null) {
        return (
            <MetaLink
                label={searchLabel}
                href={directSearchUrl}
                icon="magnifying-glass"
                classes={['m-headerSearchButton']}
            />
        );
    }

    const baseClassName = 'm-headerSearchForm';

    return (
        <div className={baseClassName}>
            <MetaLink
                classes={[`${baseClassName}__toggle`]}
                label={searchLabel}
                onClick={handleChange}
                icon="magnifying-glass"
            />

            <div className={`${baseClassName}__overlay ${isComponentVisible ? '' : '-closed'}`} ref={ref}>
                <form action={searchFormAction} method="get">
                    <TextInput
                        name="search-term"
                        id="search-term"
                        classes={['a-input--search']}
                        attrs={{
                            placeholder,
                            autoFocus: false,
                            ref: inputRef,
                        }}
                    />
                    <MetaLink
                        classes={[`${baseClassName}__searchButton`]}
                        type="submit"
                        icon="magnifying-glass"
                    >
                        <span className="u-sr-only">{searchLabel}</span>
                    </MetaLink>
                </form>
            </div>
        </div>
    );
};

HeaderSearchForm.defaultProps = {
    placeholder: null,
    searchFormAction: null,
    directSearchUrl: null,
};

export default HeaderSearchForm;
